import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/media/logo/logo.png";

const AuthLayout = ({ children }) => {
  return (
    <div className="container">
      <div className="left">
        <div
          style={{
            maxWidth: 400,
            marginTop: 60,
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <img src={logo} alt="" style={{ width: "70%" }} />{" "}
          </Link>
        </div>
        <div
          className="children"
          style={{ maxWidth: 400, margin: "10px auto" }}
        >
          {children}
        </div>
      </div>
      <div className="right">
        <div className="diagonal"></div>
      </div>
    </div>
  );
};

export default AuthLayout;
