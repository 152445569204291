import React, { useState } from "react";
import { Input, message } from "antd";

const VerifyEmail = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleChange = (value, index) => {
    // Chỉ cho phép nhập số
    if (/[^0-9]/.test(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); 
    setOtp(newOtp);

    // Nếu người dùng nhập, focus sang ô tiếp theo
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  // Xử lý dán mã OTP
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6); // Lấy tối đa 6 ký tự
    const newOtp = [...otp];
    let pasteIndex = 0;

    // Dán dữ liệu vào các ô tương ứng
    for (let i = 0; i < otp.length; i++) {
      if (pasteIndex < pastedData.length) {
        newOtp[i] = pastedData[pasteIndex];
        pasteIndex++;
      }
    }

    setOtp(newOtp);
    document.getElementById(`otp-${pasteIndex - 1}`).focus(); // Focus vào ô cuối cùng đã dán
  };

  // Xử lý khi người dùng bấm Enter
  const handleSubmit = () => {
    const otpCode = otp.join("");
    if (otpCode.length === 6) {
      message.success(`Mã OTP: ${otpCode}`);
    } else {
      message.error("Vui lòng nhập đầy đủ mã OTP!");
    }
  };

  // Xử lý xóa ký tự
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }

    // Xử lý khi nhấn Enter để xác nhận
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h2>Nhập mã OTP</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        {otp.map((digit, index) => (
          <Input
            key={index}
            id={`otp-${index}`}
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            onPaste={handlePaste}
            maxLength={1} 
            style={{
              width: "50px",
              height: "50px",
              textAlign: "center",
              fontSize: "18px",
            }}
            onFocus={(e) => e.target.select()} 
            onKeyDown={(e) => handleKeyDown(e, index)} 
          />
        ))}
      </div>
      <div style={{ marginTop: "20px" }}>
        <button
          onClick={handleSubmit}
          style={{
            padding: "10px 20px",
            background: "#1890ff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Xác nhận
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;
