import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FiSmartphone } from "react-icons/fi";
import { Flex, } from "antd";
import { BiLogIn } from 'react-icons/bi';
import { AiFillHome } from "react-icons/ai"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { FiSettings } from "react-icons/fi"
import { HiOutlineBookOpen } from "react-icons/hi"
import { HiOutlineNewspaper } from "react-icons/hi"
import { BsPatchQuestion } from "react-icons/bs"
import { MdContactMail } from "react-icons/md"
import { MdComputer } from "react-icons/md"
import { FaBars } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import './header.css'
import logo from '../../assets/media/logo/logo.png'
export default function Header() {
  const userIsAuthenticated = false;
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const handleScrollToDownload = () => {
    const element = document.getElementById("download");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <div className="navbar">
        <div className="nav-container">
          <NavLink to="/" className="nav-logo">
            <img
              src={logo}
              alt=""
            />
          </NavLink>
          <div className={click ? "nav-menu active" : "nav-menu"}>
            <div className="nav__top">
              {/* <Link to="#download"> */}
                <div className="download item_header hvr-bounce-to-top  "onClick={handleScrollToDownload}>
                  <i className="hone">
                    <FiSmartphone />
                  </i>
                  Tải ứng dụng
                </div>
              {/* </Link> */}
              {userIsAuthenticated ? (
                <div>
                  {/* <NavLink

                  to="/user"
                >
                  Xin chào {inforUser ? inforUser.name : "Ok"}
                </NavLink> */}
                  <Flex gap={10} align="center">
                    {/* <Dropdown menu={{ items }}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Flex gap={10} style={{ marginRight: 10 }}>
                          <Avatar size={"large"} src={inforUser?.avatar} />

                          <Flex
                            align="center"
                            gap={5}
                            className="box_infAdminUser"
                          >
                            <p>{inforUser ? inforUser.name : "Khách"}</p>
                          
                          </Flex>
                        </Flex>
                      </a>
                    </Dropdown> */}
                  </Flex>
                </div>
              ) : (
                <div className="login  item_header">
                  <NavLink to="/dang-nhap">
                    <i className="header_login">
                      <BiLogIn />
                    </i>
                    Đăng Nhập
                  </NavLink>
                </div>
              )}
            </div>
            <div className="nav__bot">
              <ul>
                <li className="nav-item">
                  <NavLink
                    to="/"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      {" "}
                      <AiFillHome />{" "}
                    </i>{" "}
                    Trang chủ
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    to="/gioi-thieu"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      {" "}
                      <AiOutlineInfoCircle />
                    </i>{" "}
                    Giới Thiệu
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    to="quy-trinh"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      {" "}
                      <FiSettings />{" "}
                    </i>{" "}
                    Quy trình
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="huong-dan"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      <HiOutlineBookOpen />
                    </i>{" "}
                    Hướng Dẫn
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="tin-tuc"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      <HiOutlineNewspaper />
                    </i>{" "}
                    Tin Tức
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="thac-mac"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      <BsPatchQuestion />
                    </i>{" "}
                    Thắc Mắc
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="lien-he"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      <MdContactMail />
                    </i>{" "}
                    Liên Hệ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="phong-kham"
                    
                    className={({ isActive }) => (isActive ? 'nav-links active' : 'nav-links')}
                    onClick={handleClick}
                  >
                    <i className="icon__mobile">
                      <MdComputer />
                    </i>{" "}
                    Phòng Khám
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <GrClose /> : <FaBars />}
          </div>
        </div>
      </div>
     
    </>
  );
}
