import React from "react";
// import "./login.css";
import { Form, Input, Button, message, Typography } from "antd";
import { Link } from "react-router-dom";
export default function Register() {
  const onFinish = (values) => {
    // Xử lý khi form hợp lệ
    console.log("Form Submitted: ", values);
    message.success("Đăng ký thành công!");
  };

  const onFinishFailed = (errorInfo) => {
    // Xử lý khi form không hợp lệ
    console.log("Validation Failed: ", errorInfo);
    message.error("Vui lòng kiểm tra lại thông tin!");
  };

  return (
    <>
      <Typography style={{ textAlign: "center" }}>
        Vui lòng đăng ký để tiếp tục
      </Typography>
      <Form
        name="login_form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* Name Input */}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Nhập tên của bạn" }]}
        >
          <Input className="auth-input-custom" placeholder="Nhập tên của bạn" />
        </Form.Item>
        {/* Email Input */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Vui lòng ký email!" },
            { type: "email", message: "Email không hợp lệ!" },
          ]}
        >
          <Input className="auth-input-custom" placeholder="Nhập email của bạn" />
        </Form.Item>

        {/* Password Input */}
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            { required: true, message: "Vui lòng ký mật khẩu!" },
            { min: 6, message: "Mật khẩu phải ít nhất 6 ký tự!" },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
              message:
                "Mật khẩu phải chứa ít nhất 1 chữ in hoa, 1 chữ in thường và 1 ký tự đặc biệt!",
            },
          ]}
        >
          <Input.Password className="auth-input-custom" placeholder="Nhập mật khẩu của bạn" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Đăng ký
          </Button>
        </Form.Item>
      </Form>
      <Typography>
        Đã có tài khoản ?<Link to="/dang-nhap"> Đăng nhập ngay</Link>
      </Typography>
    </>
  );
}
