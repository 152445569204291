import { Button, Form, Input, message, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const onFinish = (values) => {
    console.log("Form Submitted: ", values);
    message.success("thành công!");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Validation Failed: ", errorInfo);
    message.error("Vui lòng kiểm tra lại thông tin!");
  };
  return (
    <div>
      <Typography style={{ textAlign: "center" }}>
        Quên mật khẩu
      </Typography>
      <Form
        name="login_form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Vui lòng nhập email!" },
            { type: "email", message: "Email không hợp lệ!" },
          ]}
        >
          <Input
            className="auth-input-custom"
            placeholder="Nhập email của bạn"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Gửi
          </Button>
        </Form.Item>
      </Form>
      <Typography>
        Bạn đã nhớ mật khẩu ?<Link to="/dang-nhap"> Đăng nhập ngay</Link>
      </Typography>
    </div>
  );
}
