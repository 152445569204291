import React from "react";
import "./login.css";
import { Form, Input, Button, message, Typography } from "antd";
import { Link } from "react-router-dom";
export default function Login() {
  const onFinish = (values) => {
    console.log("Form Submitted: ", values);
    message.success("Đăng nhập thành công!");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Validation Failed: ", errorInfo);
    message.error("Vui lòng kiểm tra lại thông tin!");
  };

  return (
    <div>
      <Typography style={{ textAlign: "center" }}>
        Vui lòng đăng nhập để tiếp tục
      </Typography>
      <Form
        name="login_form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Vui lòng nhập email!" },
            { type: "email", message: "Email không hợp lệ!" },
          ]}
        >
          <Input className="auth-input-custom" placeholder="Nhập email của bạn" />
        </Form.Item>

        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            { required: true, message: "Vui lòng nhập mật khẩu!" },
            { min: 6, message: "Mật khẩu phải ít nhất 6 ký tự!" },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
              message:
                "Mật khẩu phải chứa ít nhất 1 chữ in hoa, 1 chữ in thường và 1 ký tự đặc biệt!",
            },
          ]}
        >
          <Input.Password className="auth-input-custom" placeholder="Nhập mật khẩu của bạn" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Đăng Nhập
          </Button>
        </Form.Item>
      </Form>
      <Typography>
        <Link to="/quen-mat-khau"> Quên mật khẩu</Link>
      </Typography>
      <Typography>
        Chưa có tài khoản ?<Link to="/dang-ky"> Đăng ký ngay</Link>
      </Typography>
    </div>
  );
}
