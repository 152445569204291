import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const usersSlice = createSlice({
    name: 'users',
    initialState: { status: 'idle', users: [] },
    reducers: {
        getUsers(state, action) {
            state.status = 'loading'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                state.status = 'loading';
            })

    },
})
export const fetchUsers = createAsyncThunk('todos/fetchTodos', async () => {
    const res = await fetch('/api/todos');
    const data = await res.json();
    return data.todos;
});
export default usersSlice;