import { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes/routes"; // Đảm bảo bạn đã định nghĩa publicRoutes
import DafaultLayout from "./layout/DefaultLayout/DafaultLayout.js"; // Lưu ý chính tả DefaultLayout
import { setHeadersAuthorization } from "./utils/httpRequest";
import './css/main.css';
import ScrollToTop from "./utils/ScrollToTop.js";

// App component
function App() {
  // Set headers authorization khi app load
  setHeadersAuthorization();

  return (

    <Router>
        <ScrollToTop>
      <div className="App">
          <Routes>
            {publicRoutes.map((route, index) => {
              const Page = route.element; // Sử dụng element thay vì component
              let Layout = DafaultLayout;

              if (route.layout) {
                Layout = route.layout;
              } else if (route.layout === null) {
                Layout = Fragment; // Không có layout thì dùng Fragment
              }

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
          </Routes>
      </div>
        </ScrollToTop>
    </Router>
  );
}

export default App;
