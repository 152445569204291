import { toast } from "react-toastify"


const toastMessage = {
    showSuccess: (message) => {
        toast.success(message || 'Thao tác thành công!');
    },
    showError: (message) =>{
        toast.error(message || 'Thao tác thất bại')
    }
}

export default toastMessage