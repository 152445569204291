const authToken = {
    getTokenFromLocalStorage: () => {
        const token = localStorage.getItem(process.env.LOCAL_STORAGE_TOKEN_KEY)
        return token
    },
    setTokenToLocalStorage: (token) => {
        localStorage.setItem(process.env.LOCAL_STORAGE_TOKEN_KEY, token)
    },
    removeTokenFromLocalStorage: () => {
        localStorage.removeItem(process.env.LOCAL_STORAGE_TOKEN_KEY)
    }
}

export default authToken