import React from "react";
import "./homepage.css";
export default function Home() {
  return (
    <div>
      <div className="homepage_banner">
        Nền tảng công nghệ <br />
        Kết nối người dân với Cơ sở - Dịch vụ Y tế
      </div>
      <div style={{marginTop:800}} id="download">
        haha
      </div>
    </div>
  );
}
