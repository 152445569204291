import config from '../config';
import AuthLayout from '../pages/Auth/AuthLayout';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Home from '../pages/Home/Home';
import Introduce from '../pages/Introduce/Introduce';
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail';

// Public routes
const publicRoutes = [
    { path: config.routes.home, element: Home },
    { path: config.routes.introduce, element: Introduce },

    { path: config.routes.login, element: Login, layout: AuthLayout },
    { path: config.routes.register, element: Register, layout: AuthLayout },
    { path: config.routes.verifyEmail, element: VerifyEmail, layout: AuthLayout },
    { path: config.routes.forgotPassword, element: ForgotPassword, layout: AuthLayout },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
