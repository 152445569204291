import axios from "axios";
import toastMessage from "./toastMessage";
import authToke from './authToken'

const httpRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

httpRequest.interceptors.response.use(
    (response) => {
        const method = response.config.method.toLocalLowerCase();
        if (['post', 'put', 'delete'].includes(method)) {
            toastMessage.showSuccess();
        }
        return response;
    },
    (error) => {
        toastMessage.showError();
        return Promise.reject(error);
    }
);

export const setHeadersAuthorization = () => {
    const token = authToke.getTokenFromLocalStorage();
    if (token) {
        httpRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
}

export default httpRequest